@import '../../../../assets/css/variables';

.theme-case-tabs-cover {
    .theme-nav-bordered {
        // border-bottom: 1px solid $light-border-subtle;

        .nav-link {
            font-size: $theme-size-14;
            --bs-nav-link-padding-x: 1.5rem;
            min-width: 130px;
            &.active {
                background-color: var(--bs-primary) !important;
                color: var(--bs-white) !important;
                font-weight: 700;
            }
        }
    }
}

@keyframes opacity-animation {
    0% {
        opacity: 0
    }
}

@keyframes pulse-animation {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: .8
    }

    70% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(3)
    }

    to {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0
    }
}

.pulse-circle {
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: $danger;
        border-radius: 100%;
        opacity: .5;
        transform: translate(-50%, -50%) scale(3);
        animation: pulse-animation 3s infinite;

    }
}